import './LoadingProgress.scss';
import { Backdrop, LinearProgress } from '@mui/material'

function LoadingProgress({ text }: { text?: string }) {
    return <Backdrop open={true} data-testid='loading-progress' data-cyid='cy-loading-progress-component' className='loading-progress-component'>
        <p>In Progress</p>
        <LinearProgress data-testid='loading-progress-linear' className='loading-progress-component-progress' color='inherit' />
        {text && <p>{text}</p>}
    </Backdrop>
}

export { LoadingProgress }