import { ReactElement } from 'react';
import './Popup.scss';
import { Modal, Box, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from 'components/Button/Button';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { classNames } from 'common/helpers';

const CenteredModal = styled(Modal)(({ theme }) => {
	return {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiBackdrop-root': {
			zIndex: theme.zIndex.drawer + 1,
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};
});
const CustomBackdrop: React.ElementType<any> | undefined = styled(Backdrop)({
	backgroundColor: 'rgba(0, 0, 0, 0.3)',
});

const ContentBox = styled(Box)(() => ({
	borderRadius: '7px',
}));

interface PopupProps {
	open: boolean;
	onClose: (ev?: MouseEvent) => void;
	children: ReactElement;
	submitButtonText?: string;
	cancelButtonText?: string;
	onSubmit?: () => void;
	isSubmitDisabled?: boolean;
	withButtons?: boolean;
	buttonsSectionContent?: ReactElement;
	className?: string;
	data_cyid: string;
	showCloseButton?: boolean;
}

const Popup = ({
	open,
	onClose,
	onSubmit = () => { },
	children,
	submitButtonText,
	cancelButtonText = 'Cancel',
	isSubmitDisabled,
	withButtons = true,
	buttonsSectionContent,
	className,
	data_cyid = 'cy-popup-component-modal',
	showCloseButton = true,
	...rest
}: PopupProps) => {
	return (
		<>
			<CenteredModal
				{...rest}
				open={open}
				onClose={(event, reason) => {
					if (reason !== 'escapeKeyDown') {
						onClose();
					}
				}}
				data-cyid={data_cyid}
				className='popup-component-main'
			>
				<CustomBackdrop open={open}>
					<ContentBox
						data-cyid='cy-popup-component'
						className={classNames('popup-component', className)}
					>
						<div
							data-cyid='cy-popup-component-header'
							className='popup-component-header'
						>
							{showCloseButton && (
								<button
									type='button'
									onClick={(ev: MouseEvent | any) => {
										ev.stopPropagation();
										onClose();
									}}
									data-cyid='cy-close-popup-button'
									data-testid='cy-close-popup-button'
								>
									<CloseIcon />
								</button>
							)}
						</div>
						<div
							data-cyid='cy-popup-component-content'
							className='popup-component-content'
						>
							{children}
							<div
								className={classNames(
									'popup-component-footer',
									!submitButtonText && 'single'
								)}
							>
								{buttonsSectionContent}
								{withButtons && (
									<div className='popup-component-buttons'>
										{submitButtonText && (
											<Button
												className='blue'
												text={submitButtonText}
												onClick={onSubmit}
												disabled={isSubmitDisabled}
												data_cyid='cy-popup-submit-actions'
												data-testid='popup-submit-actions'
											/>
										)}
										<Button
											text={cancelButtonText}
											// onClick={onClose}
											onClick={(ev: MouseEvent | any) => {
												ev.stopPropagation();
												onClose();
											}}
											className='plain'
											data_cyid='cy-popup-close-action'
										/>
									</div>
								)}
							</div>
						</div>
					</ContentBox>
				</CustomBackdrop>
			</CenteredModal>
		</>
	);
};

export { Popup };
