import { CopyAll } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import './CopyButton.scss'

interface CopyButtonProps {
    handleCopyClick: () => void
    showCopiedText: boolean
    isCopied: boolean
}

const CopyButton = ({ handleCopyClick, showCopiedText, isCopied }: CopyButtonProps) => {
    return (
        <div data-testid='copy-button-component' className='copy-button'>
            <IconButton onClick={handleCopyClick} className='small blue' data-testid='copy-icon-button' title='Copy'>
                <CopyAll />
            </IconButton>
            {isCopied && (
                <span data-testid='copied-indicator-text' className={`copy-feedback ${showCopiedText ? 'fade-in' : 'fade-out'}`}>
                    Copied!
                </span>
            )}
        </div>
    )
}

export default CopyButton