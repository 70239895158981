import React, { useState } from 'react';
import { Autocomplete, TextField, Typography, Box, ListItemText, ListItem } from '@mui/material';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { ArrowDropDown } from '@mui/icons-material';

interface ProjectOption {
    label: string;
    value: string;
}

interface ClickableTextSearchProps {
    projects: ProjectOption[];
    onProjectSelect: (selected: ProjectOption | null) => void;
    tooltipContent: React.ReactElement | string;
    label: string;
}

const ClickableTextSearch: React.FC<ClickableTextSearchProps> = ({ label, projects, onProjectSelect, tooltipContent }) => {
    const [isSearchActive, setIsSearchActive] = useState(false);

    const handleTextClick = () => {
        setIsSearchActive(true);
    };

    const handleProjectSelect = (selected: ProjectOption | null) => {
        onProjectSelect(selected);
        setIsSearchActive(false);
    };

    return (
        <Box
            sx={{
                width: 270,
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                transition: 'all 0.3s ease',
            }}
        >
            {isSearchActive ? (
                <Autocomplete
                    freeSolo
                    open={isSearchActive}
                    options={projects as ProjectOption[]}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                    onChange={(event, value) => handleProjectSelect(value as ProjectOption | null)}
                    sx={{
                        width: '100%',
                        transition: 'opacity 0.3s ease',
                        opacity: isSearchActive ? 1 : 0,
                    }}
                    renderOption={(props, option) => (
                        <ListItem
                            {...props}
                            key={option.value}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 12px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 123, 255, 0.1)', // Light blue on hover
                                },
                            }}
                        >
                            <Box
                                component="span"
                                sx={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '50%',
                                    backgroundColor: 'primary.main',
                                    marginRight: 1,
                                }}
                            />
                            <ListItemText
                                primary={option.label}
                                primaryTypographyProps={{
                                    fontSize: '0.9rem',
                                    color: 'text.primary',
                                    fontWeight: 500,
                                }}
                            />
                        </ListItem>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder="Search Projects"
                            autoFocus
                            onBlur={() => setIsSearchActive(false)}
                            sx={{
                                '& .MuiInputBase-root': {
                                    minHeight: '28px',
                                    padding: '0px',
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: '4px 8px',
                                    height: 'auto',
                                },
                            }}
                        />
                    )}
                />
            ) : (
                <Typography
                    variant="body1"
                    onClick={handleTextClick}
                    sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        '&:hover': {
                            color: 'primary.dark',
                        },
                        transition: 'color 0.3s ease',
                        display: 'flex'
                    }}
                >
                    {label}
                    <ArrowDropDown />
                </Typography>
            )}
            {!!tooltipContent && (
                <Box
                    sx={{
                        display: 'inline-block',
                        marginLeft: isSearchActive ? '8px' : '16px',
                        transition: 'margin-left 0.3s ease',
                    }}
                >
                    <CustomizedTooltip
                        tooltipContent={tooltipContent}
                        placement='bottom-end'
                        data_cyid='cy-tooltip-page-header-label'
                    />
                </Box>
            )}
        </Box>
    );
};

export default ClickableTextSearch;
