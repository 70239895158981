import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { classNames } from 'common/helpers';
import React, { useMemo } from 'react';

const Pagination = ({
	buttons,
	onPageChange,
	activePage,
}: {
	buttons: number[];
	onPageChange: (page: number) => void;
	activePage: number;
}) => {
	const totalPages = useMemo(() => buttons.length, [buttons]);
	const handlePageChange = (page: number) => {
		if (page < 0 || page >= totalPages) return;
		onPageChange(page);
	};

	const renderPageButtons = () => {
		let pages = [];
		if (totalPages <= 6) {
			for (let i = 0; i < totalPages; i++) {
				pages.push(
					<button
						type='button'
						key={buttons[i]}
						className={classNames(activePage === i && 'active')}
						onClick={() => handlePageChange(i)}
						data-testid={`page-button-${i}`}
					>
						{buttons[i] + 1}
					</button>
				);
			}
		} else {
			if (activePage < 4) {
				// Show first 4 pages and ellipsis
				for (let i = 0; i < 5; i++) {
					pages.push(
						<button
							type='button'
							key={buttons[i]}
							className={classNames(activePage === i && 'active')}
							onClick={() => handlePageChange(i)}
							data-testid={`page-button-${i}`}
						>
							{buttons[i] + 1}
						</button>
					);
				}
				pages.push(<span key='ellipsis1' data-testid='ellipsis1'>...</span>);
				pages.push(
					<button
						type='button'
						key={buttons[totalPages - 1]}
						onClick={() => handlePageChange(totalPages - 1)}
						data-testid={`page-button-${totalPages - 1}`}
					>
						{buttons[totalPages - 1] + 1}
					</button>
				);
			} else if (activePage > totalPages - 5) {
				// Show ellipsis and last 4 pages
				pages.push(
					<button
						type='button'
						key={buttons[0]}
						onClick={() => handlePageChange(0)}
						data-testid='page-button-0'
					>
						{buttons[0] + 1}
					</button>
				);
				pages.push(<span key='ellipsis2' data-testid='ellipsis2'>...</span>);
				for (let i = totalPages - 5; i < totalPages; i++) {
					pages.push(
						<button
							type='button'
							key={buttons[i]}
							className={classNames(activePage === i && 'active')}
							onClick={() => handlePageChange(i)}
							data-testid={`page-button-${i}`}
						>
							{buttons[i] + 1}
						</button>
					);
				}
			} else {
				// Show ellipsis, active page and surrounding pages, and ellipsis
				pages.push(
					<button
						type='button'
						key={buttons[0]}
						onClick={() => handlePageChange(0)}
						data-testid='page-button-0'
					>
						{buttons[0] + 1}
					</button>
				);
				pages.push(<span key='ellipsis3' data-testid='ellipsis3'>...</span>);
				for (let i = activePage - 1; i <= activePage + 1; i++) {
					pages.push(
						<button
							type='button'
							key={buttons[i]}
							className={classNames(activePage === i && 'active')}
							onClick={() => handlePageChange(i)}
							data-testid={`page-button-${i}`}
						>
							{buttons[i] + 1}
						</button>
					);
				}
				pages.push(<span key='ellipsis4' data-testid='ellipsis4'>...</span>);
				pages.push(
					<button
						type='button'
						key={buttons[totalPages - 1]}
						onClick={() => handlePageChange(totalPages - 1)}
						data-testid={`page-button-${totalPages - 1}`}
					>
						{buttons[totalPages - 1] + 1}
					</button>
				);
			}
		}
		return pages;
	};
	return (
		<div
			data-cyid='cy-table-component-pagination-controls'
			data-testid='cy-table-component-pagination-controls'
			className='table-component-pagination-controls'
		>
			<button
				type='button'
				onClick={() => handlePageChange(activePage - 1)}
				disabled={activePage === 0}
				data-testid='prev-button'
			>
				<ArrowLeft />
			</button>
			{renderPageButtons()}
			<button
				type='button'
				onClick={() => handlePageChange(activePage + 1)}
				disabled={activePage === totalPages - 1}
				data-testid='next-button'
			>
				<ArrowRight />
			</button>
		</div>
	);
};

export default Pagination;
