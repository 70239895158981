import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConnectedClouds, setCurrentCloud } from './clouds-thunks';
import { ICloud } from 'common/interfaces';

interface CloudsState {
    data: ICloud[];
    loading: boolean;
    error: string | null;
    currentCloud: any;
    setCurrentCloud: boolean;
}

const initialState: CloudsState = {
    data: [],
    loading: false,
    error: null,
    currentCloud: null,
    setCurrentCloud: false
};

const cloudsSlice = createSlice({
    name: 'clouds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConnectedClouds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getConnectedClouds.fulfilled, (state, action: PayloadAction<{ clouds: ICloud[] }>) => {
                state.loading = false;
                state.data = action.payload.clouds;
            })
            .addCase(getConnectedClouds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to load clouds';
            })

            .addCase(setCurrentCloud.pending, (state,) => {
                state.setCurrentCloud = true;
            })
            .addCase(setCurrentCloud.fulfilled, (state, action) => {
                state.currentCloud = action.payload.currentCloud;
                state.setCurrentCloud = false;
            })
            .addCase(setCurrentCloud.rejected, (state) => {
                state.setCurrentCloud = false;
                state.currentCloud = initialState.currentCloud
            })

    },
});

export const cloudsReducer = cloudsSlice.reducer;
