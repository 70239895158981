import { useEffect, useRef, useState } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-hcl';
import 'prismjs/themes/prism.css';
import './CopyContainer.scss';
import CopyButton from 'components/CopyButton/CopyButton';

interface ClipBoardProps {
    text: string;
    language?: 'powershell' | 'bash' | 'yaml' | 'hcl' | 'shell';
    className?: string;
    icon?: React.ReactNode | null;
    mainClassName?: string;
}

const ClipBoard = ({ text, language, className, mainClassName, icon }: ClipBoardProps) => {
    const codeRef = useRef<HTMLPreElement>(null);
    const [isCopied, setCopied] = useState(false);
    const [showCopiedText, setShowCopiedText] = useState(false);

    useEffect(() => {
        Prism.highlightAll();
    }, [text, language]);

    const handleCopyClick = () => {
        if (codeRef.current) {
            navigator.clipboard
                .writeText(codeRef.current.textContent || '')
                .then(() => {
                    setCopied(true);
                    setShowCopiedText(true);

                    setTimeout(() => {
                        setShowCopiedText(false);
                    }, 3000);
                })
                .catch((error) => {
                    console.error('Unable to copy text to clipboard.', error);
                });
        }
    };

    return (
        <div className={`content__copy-container ${mainClassName || ''}`} data-cyid='cy-copy-content-container' data-testid='copy-content-container'>
            <div data-testid='copy-content-container-button' className={`content__copy-container__copy ${icon ? 'with-icon' : ''}`}>
                {icon}
                <CopyButton data-testid='copy-button' handleCopyClick={handleCopyClick} showCopiedText={showCopiedText} isCopied={isCopied} />
            </div>
            <pre ref={codeRef} className={className || 'content__copy-container__code'} data-testid='copy-content-container-code-area'>
                <code className={`language-${language}`}>
                    {text}
                </code>
            </pre>
        </div>
    );
};

export default ClipBoard;
