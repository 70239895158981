import { useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { formatDate, } from 'common/helpers';
import { IArtifactImageData, IDiscoveryOverview, IDiscoveryProject, ISubFolder, } from 'common/interfaces';
import { ITableColumn, Table } from 'components/Table/Table';
import { PageHeader } from 'components/PageHeader/PageHeader';
import '../AccountResourcesList.scss';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from 'store/discovery/discovery';
import { AccountChildrenRoutes, CoreRoutes } from 'routing/routing-paths';
import TableFilterHeader from 'components/TableFiltersHeader/TableFilterHeader';
import { FolderOpenOutlined } from '@mui/icons-material';
import { ReactComponent as SubFolder } from 'assets/svgs/subfolder.svg';
import { ApplyProjections } from 'components/ApplyProjections/ApplyProjections';
import useDiscoveryTooltipContent from 'hooks/useDiscoveryTooltipContent';
import { useSelector } from 'react-redux';
import { selectSessionData } from 'store/discovery/discovery-selectors';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

const ResourcesContainerImages = () => {
    const [page, setPage] = useState(0);
    const [subPage, setSubPage] = useState<{
        [key: string]: number;
    }>({});
    const [search, setSearch] = useState<string>('');
    const {
        account,
        discovery,
    }: { account: IDiscoveryProject; discovery: IDiscoveryOverview } =
        useOutletContext();
    const dispatch = useDispatch();



    const onPageChange = (page: number) => setPage(page);
    const setSubRepoPageChange = (repoName: string, page: number) => {
        setSubPage((prevPages) => ({
            ...prevPages,
            [repoName]: page,
        }));
    };

    /**
     * Represents the list of artifact images containers.
     * @type {Array<Object>}
     */
    const artifactImagesContainers = useMemo(() => {
        return account && account?.artifact_image_data?.map((item: IArtifactImageData, ind: number) => {
            return {
                ...item, id: item.repo_name + ind, name: item.repo_name
            }
        }, [])
    }, [account])

    const containerImagesColumn: ITableColumn<IArtifactImageData>[] = [
        {
            name: 'Repository',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-name resource-table-cell-name'>
                    {resource.repo_name}
                    {resource?.sub_folder.length > 0 && <FolderOpenOutlined sx={{ height: 17, width: 17 }} />}
                </div>
            ),
            minWidth: '200px',
            sortable: true,
            sortKey: 'repo_name'
        },
        {
            name: 'Repo Size',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-type'>
                    {resource?.repo_size} GB
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'repo_size'
        },
        {
            name: 'Repo Cost',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-type'>
                    $ {resource?.repo_cost?.toFixed(2) || 0}
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'repo_size'
        },
        {
            name: 'Location',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-type  '>
                    {resource?.location}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'location'
        },
        {
            name: 'Created On',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-type'>
                    {formatDate(resource.created_at)}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'created_at'
        },
        {
            name: 'Updated On',
            element: (resource: IArtifactImageData) => (
                <div className='logs-resource-table-cell-type'>
                    {formatDate(resource.updated_at)}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'updated_at'
        },
    ];

    const subRepoColumn: ITableColumn<ISubFolder>[] = [
        {
            name: 'Name',
            element: (resource: ISubFolder) => (
                <div className='logs-resource-table-cell-name resource-table-cell-name'>
                    {resource.sub_folder_name}
                </div>
            ),
            minWidth: '200px',
            sortable: true,
            sortKey: 'sub_folder_name'
        },
        {
            name: 'Cost',
            element: (resource: ISubFolder) => (
                <div className='logs-resource-table-cell-name '>
                    $ {resource.cost.toFixed(2)}
                </div>
            ),
            minWidth: '200px',
            sortable: true,
            sortKey: 'cost'
        },
        {
            name: 'Repo Size',
            element: (resource: ISubFolder) => (
                <div className='logs-resource-table-cell-type'>
                    {resource?.size.toFixed(3)} GB
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'size'
        },
    ];

    const { getTooltipContent } = useDiscoveryTooltipContent({ account, discovery });
    const session_connector = useSelector(selectSessionData)
    const isSingleAccount = session_data && session_connector[(session_data?.tenant_id) as string]?.isSingleAccount

    useEffect(() => {
        dispatch(setBreadCrumbsData([
            ...(!isSingleAccount
                ? [{
                    to: `${CoreRoutes.DISCOVERIES}${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}`,
                    label: 'Accounts',
                }]
                : []),
            {
                to: `${CoreRoutes.DISCOVERIES}${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}/account/${account?.id}/${AccountChildrenRoutes.OVERVIEW}`,
                label: `${account?.name}`,
            },
            {
                to: '',
                label: 'Container Images',
            },
        ]));
    }
        , [account?.id, account?.name, discovery?.id, dispatch, isSingleAccount]);

    const CollapsibleContent = (row: IArtifactImageData) => {
        const sub_repo: ISubFolder[] = row.sub_folder.map((item: ISubFolder, ind: number) => {
            return { ...item, id: item.sub_folder_name + ind }
        })
        return (
            <div className='collapsible-content'>
                <SubFolder />
                <Table
                    totalCount={sub_repo.length || 0}
                    itemsPerPage={3}
                    onPageChange={(page: number) =>
                        setSubRepoPageChange(row.repo_name, page)
                    }
                    activePage={subPage[row.repo_name] || 0}
                    rows={(sub_repo as any) || []}
                    columns={subRepoColumn}
                    className='account-resources-list'
                    placeholderText={`No sub folders found against ${row?.repo_name}`}
                    data_cyid='cy-table-whats-new-data'
                />
            </div>
        );
    };
    return (
        <div className='container-images-component'  >
            <PageHeader
                title={account?.name}
                label='Account'

                lastSync={account?.last_synced_at}
                tooltipContent={getTooltipContent()}
            />
            <ApplyProjections
                savingPercentage={account?.savings_percentage}
                potentialCost={account?.potential_cost}
                actualCost={account?.actual_cost}
                className='account-overview-component-apply-projections'
                showSavingSelection={false}
                isAccountsOverview={false}
                account={account}
                discovery={discovery}
                data_cyid='cy-account-overview-projections'
            />
            <TableFilterHeader searchValue={search} setSearchValue={setSearch} />
            <div className='container-images-component__content'>
                <Table
                    rows={artifactImagesContainers || []}
                    columns={containerImagesColumn}
                    totalCount={artifactImagesContainers?.length || 0}
                    placeholderText={search ? 'We couldn’t find any container images matching your search criteria.' : 'We couldn’t find any container images'}
                    isSkeleton={!artifactImagesContainers}
                    className='account-resources-list'
                    data_cyid='cy-container-images-list-table'
                    onPageChange={onPageChange}
                    activePage={page}
                    itemsPerPage={10}
                    searchString={search}
                    collapsible
                    collapsibleContent={CollapsibleContent}
                    collapseOnRowClick
                />
            </div>

        </div>
    )
}

export default ResourcesContainerImages