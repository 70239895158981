import { Dispatch, SetStateAction, useState } from 'react';
import './TimeRangeFilter.scss';
import { calculateDateRange } from 'common/helpers';
import { FormControlLabel, Switch } from '@mui/material';
import RangeSlider from 'components/RangeSlider/RangeSlider';

function DateRangeFilterDropdown({
	onClick,
	selectedOption,
	setSelectedOption,
	setStartDate,
	setEndDate,
}: {
	onClick: (
		startDate: Date | null,
		endDate: Date | null,
		range?: string | null
	) => void;
	selectedOption: string | null;
	setSelectedOption: Dispatch<SetStateAction<string | null>>;
	startDate: Date | null;
	endDate: Date | null;
	setStartDate: Dispatch<SetStateAction<Date | null>>;
	setEndDate: Dispatch<SetStateAction<Date | null>>;
}) {
	const [showTimeline, setShowTimeline] = useState(false);

	const handleToggle = () => {
		setShowTimeline(!showTimeline);
	};

	const handleSelectOption = (option: string) => {
		setSelectedOption(option);
	};

	return (
		<div className='time-range-filter' data-cyid='cy-time-range-filter'>
			<div className='time-range-filter-top'>
				<FormControlLabel
					control={<Switch checked={showTimeline} onChange={handleToggle} />}
					label='Timeline'
				/>
				<div className='time-range-filter-main'>
					<div
						onClick={() => {
							handleSelectOption('Today');
							const { startDate, endDate } = calculateDateRange('Today');
							setStartDate(startDate);
							setEndDate(endDate);
							onClick(startDate, endDate);
						}}
						className={`time-range-filter-main-item ${selectedOption === 'Today' ? 'active' : ''
							}`}
					>
						Today
					</div>
					<div
						onClick={() => {
							handleSelectOption('7D');
							const { startDate, endDate } = calculateDateRange('7D');
							setStartDate(startDate);
							setEndDate(endDate);
							onClick(startDate, endDate);
						}}
						className={`time-range-filter-main-item ${selectedOption === '7D' ? 'active' : ''
							}`}
					>
						7d
					</div>
					<div
						onClick={() => {
							handleSelectOption('14D');
							const { startDate, endDate } = calculateDateRange('14D');
							setStartDate(startDate);
							setEndDate(endDate);
							onClick(startDate, endDate,);
						}}
						className={`time-range-filter-main-item ${selectedOption === '14D' ? 'active' : ''
							}`}
					>
						14d
					</div>
					<div
						onClick={() => {
							handleSelectOption('30D');
							const { startDate, endDate } = calculateDateRange('30D');
							setStartDate(startDate);
							setEndDate(endDate);
							onClick(startDate, endDate);
						}}
						className={`time-range-filter-main-item ${selectedOption === '30D' ? 'active' : ''
							}`}
					>
						30d
					</div>
					<div
						onClick={() => {
							handleSelectOption('90D');
							const { startDate, endDate } = calculateDateRange('90D');
							setStartDate(startDate);
							setEndDate(endDate);
							onClick(startDate, endDate);
						}}
						className={`time-range-filter-main-item ${selectedOption === '90D' ? 'active' : ''
							}`}
					>
						90d
					</div>
				</div>
			</div>
			{showTimeline && (
				<RangeSlider
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					onClick={(startDate, endDate) => onClick(startDate, endDate)}
				/>
			)}
		</div>
	);
}

export default DateRangeFilterDropdown;
