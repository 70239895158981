import { client } from 'api/client';
import {
	DiscoveryType,
	IDiscovery,
	IDiscoveryOverview,
} from 'common/interfaces';

export class DiscoveriesService {
	static readonly path = '/api/discovery';
	static readonly path2 = '/api/discovery/v2';

	static getDiscovery = (discovery: string) =>
		client.get<IDiscovery>(DiscoveriesService.path, { params: { discovery } });

	static createDiscovery = (project_id: string, type: DiscoveryType) =>
		client.post<IDiscovery>(DiscoveriesService.path, { project_id, type });

	static getDiscoveryResources = (discoveryId: string) =>
		client.get(`${DiscoveriesService.path}/${discoveryId}/resources`);

	static updateDiscovery = (
		discoveryId: string,
		connector_id: string,
		type: DiscoveryType
	) =>
		client.patch(`${DiscoveriesService.path}/${discoveryId}`, {
			connector_id,
			type,
		});

	static discoverResources = (
		{ token_scope, discovery_id, discovery_type, project_id, connector_id, custodian, fcm_token }: {
			token_scope?: Record<string, boolean> | null | undefined,
			discovery_id?: string | undefined,
			discovery_type?: string[] | undefined,
			project_id?: string | undefined,
			connector_id?: string | undefined,
			fcm_token?: string | undefined,
			custodian?: boolean
		}
	) =>
		client.post<{ organisation_id: string; token_scope: any, discovery_id: string }>(
			`${DiscoveriesService.path}/v2/discover_resources`,
			{
				token_scope: { token_scope },
				discovery_type: discovery_type ? discovery_type : undefined,
			},
			{
				params: {
					discovery_id: discovery_id || undefined,
					project_id: project_id || undefined,
					connector_id: connector_id || undefined,
					fcm_token: fcm_token || undefined,
					custodian: custodian
				},
			}
		);

	static getDiscoveryOverview = (
		type: 'organisation' | 'project',
		id: string,
		connector_id: string
	) => client.get<IDiscoveryOverview>(`overview/${type}/${id}/${connector_id}`);

	static getListOfDiscoveries = (state: 'in_progress' | 'complete') =>
		client.get(`${DiscoveriesService.path2}/list_discoveries/${state}`);
}
