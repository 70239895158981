import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAWSConnector, createAzureConnector, createGCPConnector, getTokenConfig } from './connectors-thunks';

interface ConnectorPayload {
    data: Record<string, boolean>;
    token_scopes: Record<string, boolean>;
    categorical_perms: Record<string, Record<string, boolean>>;
    connectors: string[];
}

interface ConnectorState {
    loading: boolean;
    data: Record<string, boolean> | null;
    error: string | null;
    token_scope: Record<string, boolean> | null;
    categorical_perms: Record<string, Record<string, boolean>> | null;
    connectors: string[];
}


const initialState: ConnectorState = {
    data: null,
    loading: false,
    error: null,
    token_scope: null,
    categorical_perms: null,
    connectors: [],
};


const handlePending = (state: ConnectorState) => {
    state.loading = true;
    state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state: ConnectorState, action: PayloadAction<ConnectorPayload>) => {
    state.loading = false;
    state.data = action.payload.data;
    state.token_scope = action.payload.token_scopes;
    state.categorical_perms = action.payload.categorical_perms;
    state.connectors = action.payload.connectors;
};

// Helper function for handling rejected state
const handleRejected = (state: ConnectorState, action: PayloadAction<any>) => {
    state.loading = false;
    state.error = action.payload.message || 'Failed to connect';
    state.data = initialState.data;
    state.token_scope = initialState.token_scope;
    state.categorical_perms = initialState.categorical_perms;
    state.connectors = initialState.connectors;
};


const connectorSlice = createSlice({
    name: 'connectors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createGCPConnector.pending, handlePending)
            .addCase(createGCPConnector.fulfilled, handleFulfilled)
            .addCase(createGCPConnector.rejected, handleRejected)
            .addCase(createAWSConnector.pending, handlePending)
            .addCase(createAWSConnector.fulfilled, handleFulfilled)
            .addCase(createAWSConnector.rejected, handleRejected)
            .addCase(createAzureConnector.pending, handlePending)
            .addCase(createAzureConnector.fulfilled, handleFulfilled)
            .addCase(createAzureConnector.rejected, handleRejected)
            .addCase(getTokenConfig.pending, handlePending)
            .addCase(getTokenConfig.fulfilled, handleFulfilled)
            .addCase(getTokenConfig.rejected, handleRejected);
    },
});

export const connectorReducer = connectorSlice.reducer;
