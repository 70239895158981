import React, {
	useState,
	useEffect,
	useRef,
	SetStateAction,
	Dispatch,
	SyntheticEvent,
} from 'react';
import { useSelector } from 'react-redux';
import { Slider, Box, Typography, SliderThumb } from '@mui/material';
import { ReactComponent as ThumbStartIcon } from 'assets/svgs/StartThumb.svg';
import { ReactComponent as ThumbEndIcon } from 'assets/svgs/afterThumb.svg';
import { selectCurrentUser } from 'store/app/app-selectors';
import './RangeSlider.scss';

interface RangeSliderProps {
	setStartDate: Dispatch<SetStateAction<Date | null>>;
	setEndDate: Dispatch<SetStateAction<Date | null>>;
	onClick?: (startDate: Date | null, endDate: Date | null) => void;
}

interface CustomThumbComponentProps extends React.HTMLAttributes<unknown> {
	index: number;
}

const CustomThumbComponent: React.FC<CustomThumbComponentProps> = (props) => {
	const { children, ...other } = props;
	const thumbRef = useRef<HTMLSpanElement>(null);
	const [index, setIndex] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (thumbRef.current) {
			setIndex(Number(thumbRef.current.dataset.index));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thumbRef.current]);

	return (
		<SliderThumb ref={thumbRef} {...other}>
			{index === 0 ? <ThumbStartIcon /> : <ThumbEndIcon />}
			{children}
		</SliderThumb>
	);
};

const RangeSlider: React.FC<RangeSliderProps> = ({
	setStartDate,
	setEndDate,
	onClick,
}) => {
	const user = useSelector(selectCurrentUser);
	const minDate = new Date(user?.joined_on || '').getTime();
	const maxDate = new Date().getTime();
	const [dateRange, setDateRange] = useState<number[]>([minDate, maxDate]);

	/**
	 * Handles the change event of the RangeSlider component.
	 * @param {Event} event - The event object.
	 * @param {number | number[]} newValue - The new value of the RangeSlider.
	 */
	const handleChange = (
		event: Event | SyntheticEvent<Element, Event>,
		newValue: number | number[]
	) => {
		const [start, end] = newValue as number[];
		if (start && end) {
			setStartDate(new Date(start));
			setEndDate(new Date(end));
		}
		setTimeout(() => {
			onClick && onClick(new Date(start), new Date(end));
		}, 4000);
		setDateRange(newValue as number[]);
	};

	/**
	 * Formats a date string into a short month and year format.
	 * @param dateString - The date string to be formatted.
	 * @returns The formatted date string in the format "MMM YYYY".
	 */
	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		const options: Intl.DateTimeFormatOptions = {
			month: 'short',
			year: 'numeric',
		};
		return date.toLocaleDateString('en-US', options);
	};

	/**
	 * Calculates the midpoint date between the user's joined date and the current date.
	 * @returns The formatted midpoint date as a string.
	 */
	const calculateMidpointDate = () => {
		const joinedDate = new Date(user?.joined_on || '');
		const currentDate = new Date();
		const midpointTimestamp =
			(joinedDate.getTime() + currentDate.getTime()) / 2;
		const midpointDate = new Date(midpointTimestamp);
		return formatDate(midpointDate as unknown as string);
	};
	return (
		<Box
			sx={{ width: '100%', padding: '20px', position: 'relative' }}
			data-cyid='cy-range-slider-component'
			data-testid='range-slider'
		>
			<Box
				sx={{ display: 'flex', justifyContent: 'space-between' }}
				component={'div'}
				className='typography'
			>
				<Typography variant='body2'>
					{formatDate(user?.joined_on || '')}
				</Typography>
				<Typography variant='body2'>{calculateMidpointDate()}</Typography>
				<Typography variant='body2'>Now</Typography>
			</Box>
			<Slider
				slots={{ thumb: CustomThumbComponent }}
				getAriaLabel={(index) =>
					index === 0 ? 'Minimum date' : 'Maximum date'
				}
				value={dateRange}
				onChangeCommitted={handleChange}
				valueLabelDisplay='auto'
				min={minDate}
				max={maxDate}
				valueLabelFormat={(value) => new Date(value).toLocaleDateString()}
				className='range-slider'
			/>
		</Box>
	);
};

export default RangeSlider;
