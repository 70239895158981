export enum CoreRoutes {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  VALIDATE = '/validate',
  CLOUDS = '/',
  DISCOVERY_PAGE = '/discovery',
  DISCOVERIES = '/discoveries',
  CLOUD_PRICING = '/cloud-pricing',
  ACTIONS = '/actions',
  TAGS = '/tags',
  ADMIN = '/admin',
  USER = '/user-management',
  POLICY_ENGINE = '/policy-engine',
  REPORTING = '/reporting',
  CUDS = '/cuds',
  WHAT_NEW = '/whatsnew',
  PAYMENT = '/payment',
  SCHEDULED_TASKS = '/scheduled-tasks',
  PRIVAY_POLICIES = '/privacy-policies',
  TERMS_CONDITIONS = '/terms-conditions',
  DASHBOARD = '/dashboard',
}

export enum DashboardChildrenRoutes {
  CLIENT_OVERVIEW = 'clients',
  OVERVIEW = 'overview'
}
export enum DiscoveryChildrenRoutes {
  ACCOUNT_OVERVIEW = 'account',
  OVERVIEW = 'overview'
}

export enum ViewTypeRoutes {
  CARDS = 'cards',
  LIST = 'list',
  MAP = 'map'
}

export enum AccountChildrenRoutes {
  INVENTORY = 'inventory',
  OLD_INVENTORY = 'old-inventory',
  OVERVIEW = 'overview',
  ACTIONS = 'actions',
  CUDS = 'cuds',
  SNAPSHOTS = 'snapshots',
  IPS = 'ips',
  CONTAINER_IMAGES = 'container-images',
  BILLING = 'billing'
}

export enum AccountActionsChildrenRoutes {
  COMPUTE = 'compute',
  VOLUMES = 'volumes',
  DATABASE = 'database',
  LOGGING = 'logging',
  GKE = 'gke',
  DELETE = 'delete',
  SCALE = 'scale',
}

export enum AccountInventoryChildrenRoutes {
  INVENTORY = 'allinventory',
  VOLUMES = 'volumes',
  COMPUTES = 'computes',
  ORGANISATION = 'inventoryorganisation',
  DATABASES = 'databases',
  LOGGING = 'logging',
  CLUSTERS = 'clusters'
}

export enum ResourcePageChildRoutes {
  OVERVIEW = 'overview',
  ACTIVITIES = 'activities',
  POLICY = 'policy'
}

export enum UserManagementPageChildRoutes {
  ROLES_AND_PERMISSIONS = 'roles-and-permissions',
  USERS = 'users',
  GROUP_MANAGEMENT = 'group-management'
}

export enum CloudPricingChildRoutes {
  OVERVIEW = 'overview',
  ACCOUNT_OVERVIEW = 'account-overview'
}

export enum GKEChildrenRoutes {
  SCALE = 'scale',
  DELETE = 'delete',
  SUSPENDED = 'suspended',
  RIGHTSIZE = 'rightsize'
}

export enum GKEWorkloadRoutes {
  WORKLOADS = 'workload',
  OVERVIEW = 'stats',
  PODS = 'pods',
  DEPLOYMENTS = 'deployments',
  DAEMON_SETS = 'daemon',
  STATEFUL_SETS = 'stateful',
  REPLICA_SETS = 'replica',
  REPLICATION_CONTROLLERS = 'replication',
  JOBS = 'jobs',
  CRON_JOBS = 'cronjobs'
}

export enum GKENetworkRoutes {
  NETWORK = 'network',
  SERVICES = 'services',
  ENDPOINTS = 'endpoints',
  INGRESSES = 'ingresses',
  INGRESSES_CLASSES = 'ingressesclasses'
}

export enum GKEConfigRoutes {
  CONFIG = 'config',
  HPA = 'hpa',
  SECRETS = 'secrets',
  CONFIG_MAPS = 'configmaps',
}

export enum GKEAccessControlRoutes {
  ACCESS_CONTROL = 'accesscontrol',
  SERVICE_ACCOUNTS = 'serviceaccounts',
  CLUSTER_ROLES = 'cluserroles',
  CLUSTER_ROLE_BINDINGS = 'clusterrolebindings',
  ROLE_BINDINGS = 'rolebindings',
  ROLES = 'roles',
  POD_SECURITY_POLICIES = 'podsecuritypolicies'
}

export enum GKEStorageRoutes {
  STORAGE = 'storage',
  PERSISTENT_VOLUME_CLAIMS = 'pvc',
  PERSISTENT_VOLUMES = 'persistentvolumes',
  STORAGE_CLASSES = 'storageclasses',
}

export enum PolicyEngineChildRoutes {
  EAZYOPS_POLICIES = 'policy-engine',
  MY_POLICIES = 'eazyops-policies',
}

export enum ReportingChildRoutes {
  SAVED = 'saved',
  DOWNLOADED = 'downloaded',
}