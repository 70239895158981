import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CoreRoutes } from 'routing/routing-paths';

const AUTH_STORAGE_KEY = 'authStatus';

export const useAuthSync = () => {
    const navigate = useNavigate();

    // This hook runs once when the component mounts
    useEffect(() => {
        const syncAuthStatus = (event: StorageEvent) => {
            if (event.key === AUTH_STORAGE_KEY) {
                const authStatus = JSON.parse(event.newValue || 'null');

                if (authStatus?.isLoggedIn) {
                    // If the user is logged in, redirect them to a protected route (e.g., dashboard)
                    navigate(CoreRoutes.CLOUDS);
                    window.location.reload()
                } else {
                    // If the user is logged out, redirect to login page
                    navigate('/sign-in');
                }
            }
        };

        // Listen to storage changes from other tabs
        window.addEventListener('storage', syncAuthStatus);

        // Clean up listener when the component unmounts
        return () => {
            window.removeEventListener('storage', syncAuthStatus);
        };
    }, [navigate]);

    // Function to broadcast login status change to all tabs
    const broadcastAuthStatus = (isLoggedIn: boolean) => {
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify({ isLoggedIn }));
    };

    return { broadcastAuthStatus };
};
