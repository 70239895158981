// useCloudDetail.ts
import { cloudIcons } from 'common/helpers';
import { ICloud } from 'common/interfaces';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCloud } from 'store/clouds/clouds-selectors';

const useCloudDetail = (cloudId?: string): ICloud => {
    const currentCloud = useSelector(selectCurrentCloud);

    const IconComponent = useMemo(() => {
        const id = cloudId || currentCloud?.id;
        return cloudIcons[id] || null; // Return the component itself, not an instantiated element.
    }, [cloudId, currentCloud]);

    return { ...currentCloud, icon: IconComponent };
};

export default useCloudDetail;
