import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import { ArrowForwardIos } from '@mui/icons-material';
import { openAuthPage } from '../../Discoveries/AuthConnectionButton/AuthConnectionButton';
import PermissionsIcon from 'assets/images/permissions.png';
import AWSCredentials from 'features/Discoveries/CredentialsBaseConnections/AWSCredentials';
import AzureCredentials from 'features/Discoveries/CredentialsBaseConnections/AzureCredentials';
import { ReactComponent as UploadTokenIcon } from 'assets/svgs/uploadTokenIcon.svg';
import './ConnectCloud.scss';

export function CloudConnectUI({ selectedCloud }: { selectedCloud?: any }) {
	const navigate = useNavigate();
	const location = useLocation();
	const currentUser = useSelector(selectCurrentUser);

	const [selectedMode, setSelectedMode] = useState({
		isCloud: true,
		isToken: false,
		isAWS: true,
		isAzure: true,
	});

	const handleModeSelection = (mode: string): void => {
		switch (mode) {
			case 'cloud':
				setSelectedMode({
					isCloud: true,
					isToken: false,
					isAWS: false,
					isAzure: false,
				});
				openAuthPage();
				break;
			case 'token':
				navigate('/selectCloud/uploadToken', {
					state: { cloud: selectedCloud?.id },
				});
				setSelectedMode({
					isCloud: false,
					isToken: true,
					isAWS: false,
					isAzure: false,
				});
				break;
			case 'aws':
				setSelectedMode({
					isCloud: false,
					isToken: false,
					isAzure: false,
					isAWS: true,
				});
				break;
			case 'azure':
				setSelectedMode({
					isCloud: false,
					isToken: false,
					isAWS: false,
					isAzure: true,
				});
				break;
			default:
				break;
		}
	};

	return (
		<div
			className='connect-cloud-ui-component'
			data-cyid='cy-connect-cloud-ui-component'
		>
			<section className='connect-cloud-ui-component-top'>
				<section className='connect-cloud-ui-component__content-section'>
					<h2 className='connect-cloud-ui-component__heading-content'>
						Welcome to EazyOps,{''}
						<span className='connect-cloud-ui-component__heading-content-waiting'>
							{currentUser?.full_name}
						</span>
					</h2>
					<div className='connect-cloud-ui-component__sub-content'>
						Let's connect your {selectedCloud?.id} cloud infrastructure using
						EazyOps
					</div>
				</section>
				<section className='connect-cloud-ui-component_cloud-section'>
					{selectedCloud?.id !== 'aws' && selectedCloud?.id !== 'azure' && (
						<div
							data-cyid='cy-connect-cloud-eazy-connect-box'
							className={`${selectedMode.isCloud && !selectedMode.isToken
								? 'isSelected'
								: ''
								} connect-cloud-ui-component_cloud-section_connect`}
							onClick={() => {
								handleModeSelection('cloud');
							}}
						>
							{selectedCloud?.icon}
							<div className='column-flex-box'>
								<p>Recommended</p>
								<h3>Eazy Connect</h3>
								<span>
									Seamlessly link your <b>{selectedCloud?.name}</b> account with
									EazyOps. Connect and optimize effortlessly.
								</span>
							</div>
						</div>
					)}
					{selectedCloud?.id === 'aws' && (
						<div
							data-cyid='cy-connect-cloud-eazy-connect-box'
							className={`${selectedMode.isAWS && !selectedMode.isToken ? 'isSelected' : ''
								} connect-cloud-ui-component_cloud-section_connect aws-connect-box`}
							onClick={() => {
								handleModeSelection('aws');
							}}
						>
							<div className='column-flex-box'>
								<p>Recommended</p>
								<h3>
									<span>{selectedCloud?.icon}</span>Quick Connect
								</h3>

								<span>
									Seamlessly link your <b>{selectedCloud?.name}</b> account with
									EazyOps by entering your Access Key ID and Secret Key
								</span>
								<AWSCredentials />
							</div>
						</div>
					)}
					{selectedCloud?.id === 'azure' && (
						<div
							data-cyid='cy-connect-cloud-eazy-connect-box'
							className={`${selectedMode.isAWS && !selectedMode.isToken ? 'isSelected' : ''
								} connect-cloud-ui-component_cloud-section_connect aws-connect-box`}
							onClick={() => {
								handleModeSelection('azure');
							}}
						>
							<div className='column-flex-box'>
								<p>Recommended</p>
								<h3>
									<span>{selectedCloud?.icon}</span>Quick Connect
								</h3>

								<span>
									Seamlessly link your <b>{selectedCloud?.name}</b> account with
									EazyOps by entering your Client ID and Secret
								</span>

								<AzureCredentials />
							</div>
						</div>
					)}
					{selectedCloud?.id !== 'azure' && (
						<div
							data-cyid='cy-connect-via-upload-token'
							className={`${selectedMode.isToken && !selectedMode.isCloud
								? 'isSelected'
								: ''
								} connect-cloud-ui-component_cloud-section_connect`}
							onClick={() => {
								handleModeSelection('token');
							}}
						>
							<UploadTokenIcon height='30px' width='42px' />
							<div className='column-flex-box'>
								<h3>Upload Access Token</h3>
								<div>
									<span>
										Don't have a{' '}
										{(selectedCloud?.id as string).toLocaleUpperCase()} Access
										Token?{' '}
									</span>
									<div
										className='connect-cloud-ui-component-permissions_view'
										style={{ marginLeft: '0px', marginTop: '10px' }}
										onClick={(ev) => {
											ev.stopPropagation();
											location.pathname.includes('selectCloud')
												? navigate('/selectCloud/tokeninstructions', {
													state: { isMain: true, cloud: selectedCloud?.id },
												})
												: navigate('./tokeninstructions', {
													state: { isMain: true, cloud: selectedCloud?.id },
												});
										}}
									>
										Click here
										<ArrowForwardIos style={{ height: '10px' }} />
									</div>
								</div>
							</div>
						</div>
					)}
				</section>
			</section>
			<section className='connect-cloud-ui-component-permissions'>
				<img src={PermissionsIcon} alt='ohSnap' />
				<div className='connect-cloud-ui-component-permissions_typography'>
					Make sure your {selectedCloud?.id} Console has the necessary{' '}
					<b>IAM permissions</b> for EazyOps to set it up hassle-free!
				</div>
				<div
					className='connect-cloud-ui-component-permissions_view'
					onClick={() =>
						location.pathname.includes('selectCloud')
							? navigate('/selectCloud/permissions')
							: navigate('./permissions')
					}
				>
					View Permissions
					<ArrowForwardIos style={{ height: '10px' }} />
				</div>
			</section>
		</div>
	);
}
