import { client } from 'api/client';
import { ConnectorType } from 'common/interfaces';

const oauthConfig = process.env;
export class ConnectorsService {
	private static readonly path = 'api/connector';

	static create(
		name: string,
		type: ConnectorType,
		file?: FormData,
		token?: string | null,
		credentials?: string | null,
		project_id?: string,
		expires_in?: number | null
	) {
		return client.post<{
			data: Record<string, boolean>;
			token_scope: Record<string, boolean>;
			categorical_perms: Record<string, Record<string, boolean>>;
			connectors: string[]
		}>(ConnectorsService.path, file, {
			params: { name, type, token, credentials, project_id, expires_in: expires_in },
		});
	}

	static createAWS(
		name: string,
		access_key_id?: string,
		secret_access_key?: string,
		region?: string,
		file?: FormData,
		project_id?: string,
		expires_in?: number | null,
		session_token?: string,
		role_arn?: string
	) {
		return client.post<{
			data: Record<string, boolean>;
			token_scope: Record<string, boolean>;
			categorical_perms: Record<string, Record<string, boolean>>;
			connector: string[]
		}>(`${ConnectorsService.path}/aws`, file, {
			params: { name, access_key_id, secret_access_key, region, project_id, expires_in: expires_in, session_token, role_arn },
		});
	}

	static createAzure(
		name: string,
		subscription_id: string,
		azure_tenant_id: string,
		client_id: string,
		client_secret: string,
		project_id?: string,
		expires_in?: number | null
	) {
		return client.post<{
			data: Record<string, boolean>;
			token_scope: Record<string, boolean>;
			categorical_perms: Record<string, Record<string, boolean>>;
			connector: string[]
		}>(`${ConnectorsService.path}/azure`, null, {
			params: { name, subscription_id, azure_tenant_id, client_id, client_secret, project_id, expires_in: expires_in },
		});
	}

	static listProjects = (access_token: string | null, connector_id?: string | null) =>
		client.post<any>(`${ConnectorsService.path}/list_gcp_projects`, null, {
			params: {
				access_token: access_token || undefined,
				...(connector_id !== null && { connector_id: connector_id })
			},
		});

	static authorizeCode = (code: string | null) =>
		client.post<any>(`${ConnectorsService.path}/google_authorize_code`, null, {
			params: {
				code: code || '',
				redirect_uri: oauthConfig.REACT_APP_OAUTH_REDIRECT_URI
			},
		});

	static deleteConnection = (connector_id: string | null) =>
		client.delete<any>(`${ConnectorsService.path}/${connector_id}`);

	static getAccessToken = () =>
		client.get<any>(`${ConnectorsService.path}/access_token`);


	static getActiveConnections = (cloud) =>
		client.get<any>(`${ConnectorsService.path}/list?cloud_name=${cloud}`);


	static getTokenConfigs = (token: string, project_id: string,) =>
		client.post<{
			data: Record<string, boolean>;
			token_scope: Record<string, boolean>;
			categorical_perms: Record<string, Record<string, boolean>>;
		}>(`${ConnectorsService.path}/token_configs`, null, {
			params: {
				token: token || '',
				project_id: project_id || ''
			}
		})

	static updateConnector = (connector_id: string, body: { name: string, expires_in: number | null, credential?: string | null, updated_at?: string | null }) => {
		const requestBody = {
			name: body.name,
			...(body.expires_in !== null && { expires_in: body.expires_in }),
			...(body.credential !== null && { credential: body.credential }),
			...(body.updated_at !== null && { updated_at: body.updated_at })
		};

		return client.patch(`${ConnectorsService.path}/${connector_id}`, requestBody);
	}
}
