import { Dispatch, SetStateAction } from 'react';
import { regionCodes } from 'common/helpers'
import { components } from 'react-select';
import { Tile } from 'components/Tile/Tile';
import MultiSelectBox from 'components/SelectBox/SelectBox'
import './AWSRegionSelector.scss'

const AWSRegionSelector = ({ value, setValue }: { value: { label: string, value: string }, setValue: Dispatch<SetStateAction<{ label: string, value: string }>> }) => {
    const CustomNoOption = (props: any) => (
        <components.NoOptionsMessage {...props}>
            <span>No regions found. Please refine your search.</span>
        </components.NoOptionsMessage>
    );

    const CustomOption = (props: any) => (
        <components.Option {...props}>
            <div className='custom-region-menu'>
                <div className='region-label'>{props.data.label}</div>
                <Tile color='light-blue' text={props.data.value} className='region-code' data-testid='region-code-tile' />
            </div>
        </components.Option>
    );

    const CustomMenuList = (props: any) => (
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
    );
    return (
        <MultiSelectBox
            data_cyid='cy-aws-region'
            data-testid='aws-region-select'
            classNamePrefix='aws-region-select'
            className='aws-region'
            options={regionCodes}
            customComponents={{ MenuList: CustomMenuList, NoOptionsMessage: CustomNoOption, Option: CustomOption }}
            value={value}
            placeholder='Select a different region from the dropdown list'
            onChange={(value) => {
                setValue(value as { label: string, value: string })
            }}
            isClearable={false}
        />
    )
}

export default AWSRegionSelector