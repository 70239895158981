import { useState } from 'react';
import { DynamicTabs } from 'components/Tabs/Tabs';
import './SnippetBoard.scss';
import { separateLastLine } from 'common/helpers';
import { ReactComponent as CLIIcon } from 'assets/svgs/terminal.svg';
import { ReactComponent as TerraformIcon } from 'assets/svgs/terraform-icon.svg';
import { ReactComponent as YamlIcon } from 'assets/svgs/yaml-icon.svg';
import { Drawer, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import ClipBoard from 'components/CopyContainer/CopyContainer';
import { useLocation } from 'react-router-dom';
import { Tile } from 'components/Tile/Tile';

interface ISnippetBoardProps {
	open: boolean;
	handleClose: () => void;
	snippets: {
		yaml: { command: string };
		cli: { command: string };
		teraform: { command: string };
	};
	resourceType?: string;
	action?: string;
}

const SnippetBoard = ({
	snippets,
	open,
	handleClose,
	resourceType,
	action,
}: ISnippetBoardProps) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const location = useLocation();

	const YamlTabContent = () => {
		return (
			<div className="snippet-board-component__yaml">
				<span className="command-title">
					Apply this patch/Add this YAML and experience how effortlessly our
					recommendation works
				</span>
				{separateLastLine(snippets?.yaml?.command || '').mainText && (
					<ClipBoard
						mainClassName="yaml-top"
						text={
							separateLastLine(snippets?.yaml?.command || '').lastLine || ''
						}
						language="shell"
						className="yaml__snippet"
					/>
				)}
				<ClipBoard
					text={
						separateLastLine(snippets?.yaml?.command || '').mainText ||
						separateLastLine(snippets?.yaml?.command || '').lastLine ||
						''
					}
					language="yaml"
					icon={<YamlIcon height="20px" width="30px" />}
				/>
			</div>
		);
	};

	const VolumeCLIContent = (commands: string[]) => {
		return location.pathname.includes('delete') ? (
			<ul className="follow-steps">
				<li>
					<span>
						<strong>Step 1: </strong>
						<span className="step-title">
							Take Backup (Optional but recommended)
						</span>
					</span>
					<ClipBoard
						text={commands[0]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 2: </strong>
						<span className="step-title">Delete Disk</span>
					</span>
					<ClipBoard
						text={commands[1] || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
			</ul>
		) : (
			<ul className="follow-steps">
				<li>
					<span>
						<strong>Step 1: </strong>
						<span className="step-title">
							Stop the Instance (if the disk is attached)
						</span>
					</span>
					<ClipBoard
						text={commands[0]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 2: </strong>
						<span className="step-title">
							Take Backup (Optional but recommended)
						</span>
					</span>
					<ClipBoard
						text={commands[1]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 3: </strong>
						<span className="step-title">Resize Disk</span>
					</span>
					<ClipBoard
						text={commands[2] || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 4: </strong>
						<span className="step-title">Start Your Instance</span>
					</span>
					<ClipBoard
						text={commands[3]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
			</ul>
		);
	};

	const NodesCLIContent = (commands: string[]) => {
		return (
			commands &&
			commands?.length && (
				<ul className="follow-steps">
					<li>
						<span>
							<strong>Step 1: </strong>
							<span className="step-title">Drain a Node</span>
						</span>
						<ClipBoard
							text={commands[0]}
							language="bash"
							icon={<CLIIcon height="20px" width="30px" />}
						/>
					</li>
					<li>
						<span>
							<strong>Step 2: </strong>
							<span className="step-title">Delete A Node Pool</span>
						</span>
						<ClipBoard
							text={commands[1] || ''}
							language="bash"
							icon={<CLIIcon height="20px" width="30px" />}
						/>
					</li>
					{action !== 'delete' && (
						<li>
							<span>
								<strong>Step 3: </strong>
								<span className="step-title">
									Create a Node Pool with the Recommended Machine type
								</span>
							</span>
							<ClipBoard
								text={commands[2] || ''}
								language="bash"
								icon={<CLIIcon height="20px" width="30px" />}
							/>
						</li>
					)}
				</ul>
			)
		);
	};

	const ComputeCLIContent = (commands: string[]) => {
		return location.pathname.includes('delete') ? (
			<ul className="follow-steps">
				<li>
					<span>
						<strong>Step 1: </strong>
						<span className="step-title">Stop the Instance</span>
					</span>
					<ClipBoard
						text={commands[0]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 2: </strong>
						<span className="step-title">Delete the Instance</span>
					</span>
					<ClipBoard
						text={commands[1] || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
			</ul>
		) : (
			<ul className="follow-steps">
				<li>
					<span>
						<strong>Step 1: </strong>
						<span className="step-title">Stop the Instance</span>
					</span>
					<ClipBoard
						text={commands[0]}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 2: </strong>
						<span className="step-title">Set Machine Type</span>
					</span>
					<ClipBoard
						text={commands[1] || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
				<li>
					<span>
						<strong>Step 3: </strong>
						<span className="step-title">Start the Instance</span>
					</span>
					<ClipBoard
						text={commands[2] || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				</li>
			</ul>
		);
	};

	const CLITabContent = () => {
		const commands = snippets?.cli?.command?.split('\n');
		return (
			<div className="snippet-board-component__yaml">
				<span className="command-title">
					Run this command and see how simple it is to put our recommendation
					into action
				</span>
				<Tile
					color="green"
					className="placeholder"
					text="This assumes that you have run `gcloud init` and set the correct project id before running the suggested command"
				/>
				{resourceType && (
					<>
						<p className="placeholder">Follow the steps below</p>
						{resourceType === 'Volume' && VolumeCLIContent(commands)}
						{resourceType === 'Cluster_nodes' && NodesCLIContent(commands)}
						{resourceType === 'Compute' && ComputeCLIContent(commands)}
					</>
				)}
				{!resourceType && (
					<ClipBoard
						text={snippets?.cli?.command || ''}
						language="bash"
						icon={<CLIIcon height="20px" width="30px" />}
					/>
				)}
			</div>
		);
	};

	const TerraformTabContent = () => {
		return (
			<div className="snippet-board-component__yaml">
				<span className="command-title">
					Terraform it up and watch the magic happen – our recommendation in
					action!
				</span>
				<ClipBoard
					text={snippets?.teraform?.command || ''}
					language="hcl"
					icon={<TerraformIcon height="20px" width="35px" />}
				/>
			</div>
		);
	};

	const tabs = [
		{
			label: <div className="tabs-label">CLI </div>,
			content: <CLITabContent />,
		},
		{
			label: <div className="tabs-label">Terraform</div>,
			content: <TerraformTabContent />,
		},
		{
			label: <div className="tabs-label">YAML </div>,
			content: <YamlTabContent />,
		},
	];
	return (
		<Drawer
			open={open}
			onClose={handleClose}
			className="snippet-board-component"
			anchor="right"
		>
			<div className="drawer-header">
				<IconButton onClick={handleClose} className="close-button">
					<GridCloseIcon />
				</IconButton>
			</div>
			<DynamicTabs
				tabs={tabs}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			/>
		</Drawer>
	);
};

export default SnippetBoard;
