import React from 'react';
import Select, {
	components as defaultComponents, Props as SelectProps, ActionMeta,
	GroupBase,
	MenuPlacement,
	OptionsOrGroups,
	StylesConfig,
} from 'react-select';
interface ISelectBoxProps {
	label?: string;
	onChange:
	| ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
	| undefined;
	options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
	isMulti?: boolean;
	isClearable?: boolean;
	value: unknown;
	classNamePrefix?: string;
	className?: string;
	data_cyid: string;
	placeholder?: React.ReactNode | string;
	style?: { [x: string]: string };
	menuPlacement?: MenuPlacement | undefined;
	customComponents?: Partial<typeof defaultComponents>;
}

const MultiSelectBox = ({
	options,
	onChange,
	isMulti = false,
	isClearable = true,
	value,
	classNamePrefix = 'multi-selection-box',
	data_cyid = 'multi-selection-box',
	placeholder = 'Select option',
	style,
	menuPlacement = 'auto',
	className, customComponents
}: ISelectBoxProps) => {
	const styles: StylesConfig<any> = {
		container: (styles) => ({ ...styles, ...style, width: '100%' }),
	};
	const mergedComponents = {
		...defaultComponents,
		IndicatorSeparator: null,
		...customComponents,
	};


	return (
		<Select
			options={options}
			onChange={onChange}
			isMulti={isMulti}
			isClearable={isClearable}
			value={value}
			classNamePrefix={classNamePrefix}
			components={mergedComponents}
			placeholder={placeholder}
			data-cyid={data_cyid}
			styles={styles}
			isSearchable
			menuPosition='absolute'
			menuPlacement={menuPlacement}
			className={className}
		/>
	);
};

export default MultiSelectBox;
