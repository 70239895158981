import { createAsyncThunk } from '@reduxjs/toolkit';
import { DiscoveriesService } from 'api/services/discoveries.service';
import { toast } from 'react-toastify';

export const discoverResources = createAsyncThunk(
    'app.discoverResources',
    async (
        { token_scope, discovery_id, discovery_type, project_id, connector_id, custodian, fcm_token }:
            {
                token_scope?: Record<string, boolean> | null | undefined,
                discovery_id?: string | undefined,
                discovery_type?: string[] | undefined,
                project_id?: string | undefined,
                connector_id?: string | undefined,
                fcm_token?: string | undefined,
                custodian?: boolean
            },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await DiscoveriesService.discoverResources(
                { token_scope, discovery_id, discovery_type, project_id, connector_id, custodian, fcm_token }
            );
            return { data, loading: false, error: null };
        } catch (err: any) {
            toast.error('Something went wrong while discovering the resources')
            return rejectWithValue(err.response.data);
        }
    }
); 