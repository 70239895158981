import { ChangeEvent, useState } from 'react'
import { Provider } from 'common/interfaces';
import { Button } from 'components/Button/Button';
import { TextInput } from 'components/TextInput/TextInput';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentCloud } from 'store/clouds/clouds-selectors';
import { createAWSConnector } from 'store/connectors/connectors-thunks';
import { toast } from 'react-toastify';
import AWSRegionSelector from 'components/AWSRegionSelector/AWSRegionSelector';
import './CredentialBaseConnections.scss'

const AWSCredentials = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cloud = useSelector(selectCurrentCloud);


    const [awsInput, setAwsInput] = useState<{
        accessKey: string;
        secretKey: string;
        session_token?: string;
        role_arn?: string;
    }>({
        accessKey: '',
        secretKey: '',
        session_token: '',
        role_arn: ''
    });
    const [awsRegion, setAwsRegion] = useState<
        { label: string, value: string } | null
    >(
        { label: 'US East (N. Virginia)', value: 'us-east-1' },
    );

    return (
        <div className='aws-connect-box__aws-input'>
            <TextInput
                data_cyid='aws-access-key-id'
                id='aws-access-key-id'
                value={awsInput.accessKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAwsInput({ ...awsInput, accessKey: e.target.value })
                }
                placeholder='Access Key ID'
                className='aws-access-input'
            />
            <TextInput
                data_cyid='aws-access-key-secret'
                id='aws-access-key-secret'
                value={awsInput.secretKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAwsInput({ ...awsInput, secretKey: e.target.value })
                }
                placeholder='Access Key Secret'
                className='aws-access-input'
            />
            <TextInput
                data_cyid='aws-session_token'
                id='aws-session_token'
                value={awsInput?.session_token}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAwsInput({ ...awsInput, session_token: e.target.value })
                }
                placeholder='Session Token'
                className='aws-access-input'
            />
            <TextInput
                data_cyid='aws-role_arn'
                id='aws-role_arn'
                value={awsInput?.role_arn}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAwsInput({ ...awsInput, role_arn: e.target.value })
                }
                placeholder='Role'
                className='aws-access-input'
            />
            <AWSRegionSelector value={awsRegion} setValue={(value) => setAwsRegion(value)} />
            <Button
                text={`Connect ${cloud?.name}`}
                className='aws-connect-box__aws-connect-button blue small'
                data_cyid='cy-aws-connect'
                data-testid='aws-connection-button'
                onClick={() => {
                    dispatch(
                        createAWSConnector({
                            name: Provider.AWS,
                            access_key_id: awsInput.accessKey,
                            secret_access_key: awsInput.secretKey,
                            session_token: awsInput?.session_token,
                            role_arn: awsInput?.role_arn,
                            region: awsRegion?.value
                        })
                    )
                        .unwrap()
                        .then(() => {
                            navigate('/selectCloud/uploadToken', {
                                state: { step: 1 },
                            });
                        }).catch(() => {
                            toast.error('Invalid Credentials')
                        });
                }}
                disabled={!awsInput.accessKey || !awsInput.secretKey || !awsRegion}
            />
        </div>
    )
}

export default AWSCredentials