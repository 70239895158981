
import { client } from 'api/client';
import { ICloud, ICloudConnectionParam } from 'common/interfaces';

export class CloudsService {
    private static readonly path = 'api/clouds';

    static getConnectedClouds = () => client.get<{ connected_clouds: ICloud[] }>(`${this.path}/connected-clouds`);

    static connectCloud = (params: ICloudConnectionParam) => client.post(CloudsService.path, params)
}