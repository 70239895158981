import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportingService } from 'api/services/report.service';
import { UsersService } from 'api/services/users.service';
import { TOKEN_KEY } from 'common/constants';
import { deleteCookie } from 'common/helpers';

export const getCurrentUserInfo = createAsyncThunk('app.getCurrentUserInfo', async (token: string, { dispatch, rejectWithValue, getState }) => {
    try {
        const { data } = await UsersService.me(token);
        return { user: data, capabilities: [], };
    } catch (err: any) {
        dispatch({ type: 'RESET_STATE' });
        return rejectWithValue(err.response.data);
    }
});

export const setEmailReporting = createAsyncThunk('app.setEmailReporting', async (body: { enable: boolean, minutes: number }, { dispatch, rejectWithValue }) => {
    try {
        await ReportingService.setReportingEmailStatus(body);
        return { emailReporting: body.enable };
    } catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});
export const getEmailReporting = createAsyncThunk('app.getEmailReporting', async () => {
    try {
        const { data } = await ReportingService.getReportingEmailStatus();
        return { emailReporting: data.reporting_status };
    } catch (err: any) {
        return err.response.data;
    }
});
export const userSignOut = createAsyncThunk('app.userSignOut', async (_, { dispatch }) => {
    deleteCookie(TOKEN_KEY);
    localStorage.clear();
    dispatch({ type: 'RESET_STATE' });
});