import { createAsyncThunk } from '@reduxjs/toolkit';
import { CloudsService } from 'api/services/clouds.service';
import { ICloud } from 'common/interfaces';


export const getConnectedClouds = createAsyncThunk(
    'app.getConnectedClouds',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CloudsService.getConnectedClouds();

            const connectedClouds = data.connected_clouds.map((cloud: ICloud) => {

                if (cloud.name === 'gcp') {
                    return { ...cloud, name: 'Google Cloud Platform', id: cloud.name, icon: null };
                } else if (cloud.name === 'aws') {
                    return { ...cloud, name: 'Amazon Web Services', id: cloud.name, icon: null };
                }

                return { ...cloud, name: 'Microsoft Azure', id: cloud.name, icon: null };
            });

            return { clouds: connectedClouds };
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const setCurrentCloud = createAsyncThunk('app.setCurrentCloud', async (cloud: any) => {
    try {
        return { currentCloud: cloud };
    } catch (err: any) {
        return err
    }
});