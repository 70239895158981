import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { IReportRecommendations } from 'common/interfaces';
import RecommendIcon from 'assets/reportAssets/recommend.png';
// Define styles
const styles = StyleSheet.create({
    mainContainer: {
        marginTop: 20,
        flexDirection: 'column',
    },
    section: {
        marginBottom: 20,
    },
    heading: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    listItem: {
        marginBottom: 8,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        color: '#000000',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 24,
    },
    tick: {
        fontSize: 8,
        textAlign: 'center',
        lineHeight: 1,
        color: '#000',
    },
    actionText: {
        fontSize: '10px',
        fontFamily: 'Helvetica',
        marginLeft: '5px',
    },
    image: {
        objectFit: 'contain',
        width: '10px',
        height: '10px',
    },
});

// The PDF component
const RecommendationsPDF = ({ recommendations }: { recommendations: IReportRecommendations }) => (
    <View style={styles.mainContainer}>
        <Text style={styles.title}>Strategic Recommendations</Text>
        {/* Immediate Actions Section */}
        {recommendations.immediate_actions.length > 0 && (
            <View style={styles.section}>
                <Text style={styles.heading}>Immediate Actions</Text>
                {recommendations.immediate_actions.map((action, index) => (
                    <View key={index} style={styles.listItem}>
                        <Image src={RecommendIcon} style={styles.image} />
                        <Text style={styles.actionText}>{action}</Text>
                    </View>
                ))}
            </View>
        )}

        {/* Mid-Term Actions Section */}
        {recommendations?.mid_term_actions?.length > 0 && (
            <View style={styles.section}>
                <Text style={styles.heading}>Mid-Term Actions</Text>
                {recommendations.mid_term_actions.map((action, index) => (
                    <View key={index} style={styles.listItem}>
                        <Image src={RecommendIcon} style={styles.image} />
                        <Text style={styles.actionText}>{action}</Text>
                    </View>
                ))}
            </View>
        )}

        {/* Long-Term Actions Section */}
        {recommendations?.long_term_actions?.length > 0 && (
            <View style={styles.section}>
                <Text style={styles.heading}>Long-Term Actions</Text>
                {recommendations.long_term_actions.map((action, index) => (
                    <View key={index} style={styles.listItem}>
                        <Image src={RecommendIcon} style={styles.image} />
                        <Text style={styles.actionText}>{action}</Text>
                    </View>
                ))}
            </View>
        )}
    </View>
);

export default RecommendationsPDF;
