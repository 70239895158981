import { useState } from 'react';
import { IconButton, Step, StepLabel, Stepper } from '@mui/material';
import './SelectCloud.scss';
import { ArrowForwardIos, } from '@mui/icons-material';
import { ReactComponent as AWS } from 'assets/svgs/aws-logo.svg';
import { ReactComponent as Azure } from 'assets/svgs/azure.svg';
import { ReactComponent as GCP } from 'assets/svgs/googleCloud.svg';
import { ReactComponent as CloudNav } from 'assets/svgs/cloud_nav.svg';
import { CloudConnectUI } from 'features/Clouds/ConnectCloud/ConnectCloudUI';
import { useSelector } from 'react-redux';
import { selectCloudsData } from 'store/clouds/clouds-selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCurrentCloud } from 'store/clouds/clouds-thunks';
import { ICloud } from 'common/interfaces';
import { CloudConnectLoader } from 'features/Discoveries/DiscoveriesPlaceholder/ConnectCloudLoader';
import { selectConnector } from 'store/connectors/connectors-selectors';
import { useNavigate } from 'react-router-dom';

export interface Cloud {
    name: string;
    icon: React.ReactNode;
    id: string;
    connected?: boolean;
}

const steps = ['Select Cloud Provider', 'Connect Cloud Provider'];

const SelectCloud = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedCloud, setSelectedCloud] = useState<ICloud | null>(null);
    const { data: connectedClouds } = useSelector(selectCloudsData);
    const { loading } = useSelector(selectConnector);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const clouds: ICloud[] = [
        { name: 'Amazon Web Services', icon: <AWS />, id: 'aws', created_at: new Date().toISOString(), projects: 0 },
        { name: 'Microsoft Azure', icon: <Azure height={20} width={20} />, id: 'azure', created_at: new Date().toISOString(), projects: 0 },
        { name: 'Google Cloud Platform', icon: <GCP />, id: 'gcp', created_at: new Date().toISOString(), projects: 0 },
    ];

    const connectedCloudIds = connectedClouds?.map(cloud => cloud?.id) || [];
    const availableClouds = clouds
        .map(cloud => ({
            ...cloud,
            connected: connectedCloudIds.includes(cloud.id),
        }))
        .sort((a, b) => Number(a.connected) - Number(b.connected));

    const handleStep = (step: number) => {
        setActiveStep(step);
    };

    const handleCloudSelect = async (cloud: any) => {
        const selectedCloud = { id: cloud.id, name: cloud.name, created_at: cloud.created_at, projects: cloud.projects };
        try {
            await dispatch(setCurrentCloud(selectedCloud)).unwrap();
            setSelectedCloud(cloud);
            if (cloud.connected) {
                navigate('/discoveries');
            } else {
                setActiveStep(1);
            }
        } catch (error) {
            console.error('Failed to set current cloud:', error);
        }
    };

    return loading ? <CloudConnectLoader /> : (
        <div className='select-cloud' data-testid='select-cloud'>
            <Stepper activeStep={activeStep} alternativeLabel className='select-cloud__stepper'>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel onClick={() => index === 0 && handleStep(index)}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {/* Content for Step 0 */}
            {activeStep === 0 && (
                <div className='select-cloud-provider-step'>
                    <h1>Select Cloud Provider</h1>
                    <p>Add a new cloud provider to your EazyOps account.</p>

                    <ul className='select-cloud-list'>
                        {availableClouds.map((cloud) => (
                            <li className={`select-cloud-list-item ${cloud?.connected ? 'connected' : ''}`} key={cloud.id} onClick={() => handleCloudSelect(cloud)}>
                                <div className='select-cloud-list-item-cloud'>
                                    {cloud.icon}
                                    {cloud.name}
                                </div>
                                {/* Display if the cloud is connected */}
                                {!cloud?.connected && <IconButton>
                                    <ArrowForwardIos sx={{ height: '16px' }} />
                                </IconButton>}
                                {cloud?.connected && <CloudNav />}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Content for Step 1 */}
            {activeStep === 1 && selectedCloud && (
                <CloudConnectUI selectedCloud={selectedCloud} />
            )}
        </div>
    );
};

export default SelectCloud;
