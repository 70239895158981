import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface ISelect {
  value: string,
  setValue: (val: string) => void,
  label: string,
  options: Array<string>
}

const SelectBox = ({ value, setValue, label, options }: ISelect) => {

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <FormControl data-cyid='cy-select-box-component' data-testid='select-box-component' sx={{ m: 1, minWidth: 133 }} size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        data-cyid='cy-demo-select-small'
        value={value}
        label={label}
        onChange={handleChange}
        data-testid='select-box'
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {
          options.map((option, index) => <MenuItem data-testid={`select-box-menu-item-${index}`} key={index} value={option}>{option}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
}

export { SelectBox }