import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import './AuthConnectionButton.scss';
import { eazyConnectAuthenticatorURL } from 'common/helpers';
import { useDispatch } from 'react-redux';
import { setIsRefreshingToken } from 'store/discovery/discovery';

export const openAuthPage = () => {
	window.open(
		eazyConnectAuthenticatorURL,
		'Google Auth',
		'width=600,height=600'
	);
};
const AuthConnectionButton = () => {
	const dispatch = useDispatch();
	return (
		<button
			type='button'
			className='connect-cloud-button'
			data-cyid='cy-connect-cloud-eazy-connect-button-window-opener'
			onClick={() => {
				dispatch(setIsRefreshingToken(false));
				openAuthPage();
			}}
		>
			<PlusIcon height={'13.99px'} width={'13.99px'} /> Eazy Connect
		</button>
	);
};

export default AuthConnectionButton;
