import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { SelectBox } from 'components/Select/Select';
import { Dispatch, SetStateAction } from 'react';
import { finalPageOptions } from './data';

const styles = {
	recordsShown: {
		letterSpacing: '1px',
	},
	recordPerPage: {
		display: 'flex',
		alignItems: 'center',
		gap: '20px',
	},
};
export interface IPaginationProps {
	setPager?: Dispatch<
		SetStateAction<{ currentPage: number; pageSize: number; pages: number }>
	>;
	pager?: { currentPage: number; pageSize: number; pages: number };
	totalRecordCount?: number;
	totalPages?: number;
	recordPerPage?: number;
	data_cyid: string;
}
function CustomPagination({
	setPager,
	pager,
	totalRecordCount = 0,
	totalPages = 0,
	recordPerPage = 0,
	data_cyid = 'cy-pagination-stack',
}: IPaginationProps) {
	const { pageSize, currentPage }: any = pager;

	const pagesCount = totalPages;
	const pageArray = Array.from({ length: pagesCount }, (_, index) =>
		(index + 1).toString()
	);

	const handlePageChange = (val: string) => {
		if (!val) return;
		else
			setPager && pager && setPager({ ...pager, currentPage: parseInt(val) });
	};

	const handlePageSizeChange = (val: string) => {
		if (!val) return;
		if (pager && (currentPage === pagesCount || pageSize > totalRecordCount)) {
			setPager &&
				pager &&
				setPager({ ...pager, pageSize: parseInt(val), currentPage: 1 });
		} else setPager && pager && setPager({ ...pager, pageSize: parseInt(val) });
	};
	return (
		<Stack
			data-cyid={data_cyid}
			data-testid="custom-pagination"
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			width='100%'
			sx={{ marginTop: '10px' }}
		>
			<div style={styles.recordPerPage}>
				<SelectBox
					label='Records per page'
					setValue={handlePageSizeChange}
					value={pager?.pageSize.toString() || '20'}
					options={finalPageOptions}
					data-testid="record-per-page"
				/>
				<span style={styles.recordsShown} data-testid='records-on-page'>
					Showing <span style={{ color: '#185EE6' }}>{recordPerPage}</span>/
					{totalRecordCount}
				</span>
			</div>
			<Pagination
				color='primary'
				count={pagesCount}
				page={currentPage}
				data-testid='pagination'
				onChange={(event, value) => {
					setPager &&
						pager &&
						setPager({
							...pager,
							currentPage: value,
							pages: pagesCount,
						});
				}}
			/>
			<SelectBox
				label='Go to page'
				setValue={handlePageChange}
				value={pager?.currentPage.toString() || '1'}
				options={pageArray}
				data-testid="go-to-page"
			/>
		</Stack>
	);
}

export { CustomPagination };
