import connectorGif from 'assets/cloud-connect-loader.gif';
import './DiscoveriesPlaceholder.scss';

export const CloudConnectLoader = () => {
	return (
		<div
			className='connect-cloud-ui-component'
			data-cyid='cy-connect-cloud-ui-loader'
		>
			<section className='connect-cloud-ui-component__content-section'>
				<h2 className='connect-cloud-ui-component__heading-content'>
					Connecting Your Cloud,
					<span className='connect-cloud-ui-component__heading-content-waiting'>
						Please Wait...
					</span>
				</h2>
				<div className='connect-cloud-ui-component__sub-content_transition'>
					<div className='text-item'>
						Accessing Permission, Creating Read only tokens, Access Resources
						list
					</div>
				</div>
			</section>
			<section>
				<img src={connectorGif} alt='gifLoading' />
			</section>
		</div>
	);
};
