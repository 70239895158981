import { IDiscoveryOverview, IDiscoveryProject } from 'common/interfaces';
import './Billing.scss';
import { useOutletContext } from 'react-router-dom';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ApplyProjections } from 'components/ApplyProjections/ApplyProjections';
import useDiscoveryTooltipContent from 'hooks/useDiscoveryTooltipContent';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Tile } from 'components/Tile/Tile';

const Billing = () => {
    const {
        account,
        discovery,
    }: { account: IDiscoveryProject; discovery: IDiscoveryOverview } =
        useOutletContext();

    /**
     * Returns the tooltip content for the account overview.
     * The tooltip provides an overview of the account within the organization,
     * along with its cost breakdown and recommended actions to reduce cloud spending.
     *
     * @returns The tooltip content as a styled string.
     */
    const { getTooltipContent } = useDiscoveryTooltipContent({
        account,
        discovery,
    });

    const CustomTooltip: React.FC<{ payload?: any }> = ({ payload }) => {
        if (!payload || payload.length === 0) return null;

        const { total_cost, service_name, sku_name, project_id } =
            payload[0].payload;

        return (
            <div className='main-tooltip' data-testid='tooltip'>
                <span className='main-tooltip__resource' data-testid='tooltip-resource'>
                    {service_name || sku_name || project_id}
                </span>
                <span className='main-tooltip__value' data-testid='tooltip-value'>
                    $ {total_cost}
                </span>
            </div>
        );
    };
    return account && (
        <div
            className='billing-overview-component'
            data-cyid='cy-billing-component'
            data-testid='billing component'
        >
            <PageHeader
                title={account?.name || ''}
                label='Account'
                tooltipContent={getTooltipContent()}
                lastSync={account?.last_synced_at}
            />
            <ApplyProjections
                savingPercentage={account?.savings_percentage}
                potentialCost={account?.potential_cost}
                actualCost={account?.actual_cost}
                className='account-overview-component-apply-projections'
                showSavingSelection={false}
                isAccountsOverview={false}
                account={account}
                discovery={discovery}
                data_cyid='cy-account-overview-projections'
            />
            <div className='billing-overview-component__main' data-testid='billing-overview-main'>
                <div className='billing-overview-component__main__chart' data-testid='project-cost-chart'>
                    <div className='billing-overview-component__main__chart--header' data-testid='project-cost-chart-header'>
                        <h3>Project Cost</h3>
                        <Tile text={account?.name} color='green' />
                    </div>
                    <div className='billing-overview-component__main__chart--area' data-testid='project-cost-chart-area'>
                        <ResponsiveContainer width="100%" height={100}>
                            <BarChart layout="vertical" data={account?.billing?.project_costs}
                                margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
                                barSize={30}
                                barCategoryGap={20}
                            >
                                <XAxis type="number" hide />
                                <YAxis type="category" dataKey="project_name" hide />
                                <Tooltip content={CustomTooltip} cursor={{ fill: 'transparent' }} />
                                <Legend
                                    layout="vertical"
                                    align="left"
                                    verticalAlign="middle"
                                    wrapperStyle={{ top: '48px' }}
                                    iconType='wye'
                                    formatter={() => `$ ${account?.billing?.project_costs[0]?.total_cost}`}
                                />
                                <Bar dataKey="total_cost" fill="#f4c978" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='billing-overview-component__main__chart' data-testid='service-cost-chart'>
                    <div className='billing-overview-component__main__chart--header' data-testid='service-cost-chart-header'>
                        <h3>Service Cost</h3>
                    </div>
                    <div className='billing-overview-component__main__chart--area' data-testid='service-cost-chart-area'>
                        <ResponsiveContainer width='100%' height={400}>
                            <BarChart
                                data={account?.billing?.service_costs}
                                barSize={40}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 25,
                                }}
                                barGap={20}
                                barCategoryGap={20}
                            >
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis
                                    dataKey='service_name'
                                    tick={{ fontSize: 12 }}
                                    angle={-45}
                                    textAnchor='end'
                                    hide
                                />
                                <YAxis />
                                <Tooltip content={CustomTooltip} />
                                <Legend
                                    layout="vertical"
                                    align="center"
                                    verticalAlign="bottom"
                                    iconType="square"
                                    wrapperStyle={{ paddingTop: '20px' }}
                                    formatter={(value) => {
                                        if (value === 'total_cost') return 'Total Cost of each service used within the project';
                                        return value;
                                    }} />
                                <Bar dataKey='total_cost' fill='#4f73d3' />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='billing-overview-component__main__chart' data-testid='sku-cost-chart'>
                    <div className='billing-overview-component__main__chart--header' data-testid='sku-cost-chart-header'>
                        <h3>SKU Cost</h3>
                    </div>
                    <div className='billing-overview-component__main__chart--area' data-testid='sku-cost-chart-area'>
                        <ResponsiveContainer width='100%' height={400}>
                            <LineChart
                                data={account?.billing?.sku_costs}
                                margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
                            >
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis
                                    dataKey='sku_name'
                                    tick={{ fontSize: 10 }}
                                    angle={-45}
                                    textAnchor='end'
                                    hide
                                />
                                <YAxis />
                                <Tooltip content={CustomTooltip} />
                                <Legend
                                    layout="vertical"
                                    align="center"
                                    verticalAlign="bottom"
                                    iconType="square"
                                    wrapperStyle={{ paddingTop: '20px' }}
                                    formatter={(value) => {
                                        if (value === 'total_cost') return 'Total SKU Cost, highlighting specific resource expenditures ';
                                        return value;
                                    }} />
                                <Line
                                    type='monotone'
                                    dataKey='total_cost'
                                    stroke='#7A5195'
                                    activeDot={{ r: 8 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
