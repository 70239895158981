import {
	createContext,
	useState,
	ReactElement,
	useCallback,
	useEffect,
} from 'react';
import { INotifications } from '../Notification/Notification';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import { NotificationService } from 'api/services/notification.service';

const NotificationsListContext = createContext({
	notificationsList: [] as INotifications[],
	refreshNotifications: () => { },
});

const NotificationsListProvider = ({
	children,
}: {
	children: ReactElement;
}) => {
	const [notificationsList, setNotificationsList] = useState<INotifications[]>(
		[]
	);
	const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState<number>(
		Date.now()
	);
	const [currenPage, setCurrentPage] = useState(1);
	const currentUser = useSelector(selectCurrentUser);

	/**
	 * Retrieves notifications from the server and updates the notifications list.
	 */
	const getNotifications = useCallback(async () => {
		let localCurrentPage = currenPage;
		let totalPages = null;

		while (totalPages === null || localCurrentPage <= totalPages) {
			try {
				const { data } = await NotificationService.getNotificationsList(
					localCurrentPage,
					100
				);
				totalPages = data.pages;

				setNotificationsList((prevList) => {
					const updatedPrevList = prevList.filter(
						(notification) =>
							!data.items.some((item) => item.id === notification.id)
					);

					const newItems = data.items.filter(
						(item) =>
							!updatedPrevList.some(
								(notification) => notification.id === item.id
							)
					);

					return [...updatedPrevList, ...newItems];
				});
				if (localCurrentPage < totalPages) {
					localCurrentPage++;
					setCurrentPage(localCurrentPage);
				} else {
					break;
				}
			} catch (err) {
				return err;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Refreshes the notifications list by updating the last refresh timestamp.
	 */
	const refreshNotifications = useCallback(() => {
		setLastRefreshTimestamp(Date.now());
	}, []);

	/** Get notifications on mount */
	useEffect(() => {
		if (currentUser) getNotifications();
	}, [currentUser, getNotifications, lastRefreshTimestamp]);

	return (
		<NotificationsListContext.Provider
			value={{ notificationsList, refreshNotifications }}
		>
			{children}
		</NotificationsListContext.Provider>
	);
};

export { NotificationsListContext, NotificationsListProvider };
